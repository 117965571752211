export enum internalRole {
  newsManager = 'NewsManager',
  admin = 'Admin',
}

export enum customerRoles {
  customer = 'Customer',
}

export type roles = internalRole | customerRoles;
