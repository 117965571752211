import { UserSettingsDto } from 'src/shared/types';
import { IUserSnapshotIn } from '../user';

export const getUserSettingsAdapter = (data: UserSettingsDto): IUserSnapshotIn => {
  if (!data) {
    return null;
  }
  return {
    id: data.id,
    name: data.userInfo.name,
    roles: data.userInfo.roles,
  };
};
