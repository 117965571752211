import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
  getRoot,
} from 'mobx-state-tree';
import { getGlobalFilterModel } from 'src/mst/selectors';
import { GlobalFilterModel } from '../globalFilter';
import { StoreModel } from '../root';

import { Contract } from './contract';
import { IContractSnapshotOut } from './contract';

export const Contracts = types
  .model('ContractsModel', {
    _list: types.maybeNull(types.array(Contract)),
    searchValue: types.string,
  })
  .actions((self) => {
    let initialState: IContractsSnapshotOut;
    const {
      env: { httpClient },
    } = getEnv(self);
    const store: StoreModel = getRoot(self);
    const globalFilter: GlobalFilterModel = getGlobalFilterModel(store);
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetContracts(): void {
        applySnapshot(self, initialState);
      },
      setSearchValue(value: string): void {
        self.searchValue = value;
      },
      fetchContracts: flow(function* () {
        const { searchValue } = self;
        const { customerValue } = globalFilter;

        if (!customerValue?.[0]) {
          applySnapshot(self, {
            ...self,
            _list: [],
          });
          return;
        }

        const data = yield httpClient.post('get-contracts', {
          allowedCustomers: customerValue[0] ? customerValue : [],
          searchTerm: searchValue,
          skip: 0,
          take: 9999999,
        });
        applySnapshot(self, { ...self, _list: data.result || [] });
      }),
    };
  })
  .views((self) => {
    return {
      get list(): IContractSnapshotOut[] {
        return Array.isArray(self._list) ? [...self._list] : [];
      },
    };
  });

export type ContractsModel = Instance<typeof Contracts>;
export type IContractsSnapshotOut = SnapshotOut<typeof Contracts>;
export type IContractsSnapshotIn = SnapshotIn<typeof Contracts>;
