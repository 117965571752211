export enum damageReportProcessSteps {
  isImmediateHelpRequired = 'isImmediateHelpRequired',
  immediateHelpMessage = 'immediateHelpMessage',
  breakdownAccident = 'breakdownAccident',
  isOwnVehicleDamaged = 'isOwnVehicleDamaged',
  thirdPartyDetails = 'thirdPartyDetails',
  addMoreAccidentOpponents = 'addMoreAccidentOpponents',
  damageInfo = 'damageInfo',
  driverInfo = 'driverInfo',
  breakdownInfo = 'breakdownInfo',
  policeAndAttachments = 'policeAndAttachments',
  isOtherPartyInvolved = 'isOtherPartyInvolved',
  success = 'success',
}
