import { object, boolean, string, InferType } from 'yup';

import { damageReportFieldNames } from 'src/pages/DamageReportPage/constants';

export const ownVehicleDamagedSchema = object({
  [damageReportFieldNames.isOwnVehicleDamaged]: boolean().required().nullable(),
  [damageReportFieldNames.damageType]: string().when(
    damageReportFieldNames.isOwnVehicleDamaged,
    {
      is: (val: boolean) => val,
      then: string().required().nullable(),
      otherwise: string().nullable(),
    }
  ),
});

export type OwnVehicleDamagedSchemaType = InferType<typeof ownVehicleDamagedSchema>;
