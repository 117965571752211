import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
} from 'mobx-state-tree';

import {
  CustomerGlobalFilterItem,
  ICustomerGlobalFilterItemSnapshotIn,
  ICustomerGlobalFilterItemSnapshotOut,
} from './customerGlobalFilterItem';

export const GlobalFilter = types
  .model('GlobalFilterModel', {
    customers: types.maybeNull(types.array(CustomerGlobalFilterItem)),
    customer: types.maybeNull(types.string),
  })
  .actions((self) => {
    let initialState: IGlobalFilterSnapshotOut;
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetGlobalFilter(): void {
        applySnapshot(self, initialState);
      },
      setGlobalFilterValue(name: string, value: unknown): void {
        applySnapshot(self, { ...self, [name]: value });
      },
      setCustomers(items: ICustomerGlobalFilterItemSnapshotIn[]): void {
        applySnapshot(self, {
          ...self,
          customers: items,
          customer: items[0]?.value || null,
        });
      },
    };
  })
  .views((self) => {
    return {
      get customersList(): ICustomerGlobalFilterItemSnapshotOut[] {
        return Array.isArray(self.customers) ? [...self.customers] : [];
      },
      get customerValue(): string[] {
        return self.customer ? [self.customer] : [''];
      },
    };
  });

export type GlobalFilterModel = Instance<typeof GlobalFilter>;
export type IGlobalFilterSnapshotOut = SnapshotOut<typeof GlobalFilter>;
export type IGlobalFilterSnapshotIn = SnapshotIn<typeof GlobalFilter>;
