import { object, boolean, InferType } from 'yup';

import { damageReportFieldNames } from 'src/pages/DamageReportPage/constants';

export const moreAccidentOpponentsSchema = object({
  [damageReportFieldNames.addMoreAccidentOpponents]: boolean().required().nullable(),
});

export type MoreAccidentOpponentsSchemaType = InferType<
  typeof moreAccidentOpponentsSchema
>;
