import { useMemo } from 'react';

export enum fileFormats {
  png = 'png',
  jpg = 'jpg',
  jpeg = 'jpeg',
  svg = 'svg',
  doc = 'doc',
  docx = 'docx',
  xls = 'xls',
  xlsx = 'xlsx',
  pdf = 'pdf',
  msg = 'msg',
}

export enum mimeTypes {
  imagePng = 'image/png',
  imageJpeg = 'image/jpeg',
  imageSvgXml = 'image/svg+xml',
  msWord = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls = 'application/vnd.ms-excel',
  pdf = 'application/pdf',
  msg = 'application/vnd.ms-outlook',
}

const formatsAndMimeTypeMap = {
  [fileFormats.png]: mimeTypes.imagePng,
  [fileFormats.jpg]: mimeTypes.imageJpeg,
  [fileFormats.jpeg]: mimeTypes.imageJpeg,
  [fileFormats.svg]: mimeTypes.imageSvgXml,
  [fileFormats.doc]: mimeTypes.msWord,
  [fileFormats.docx]: mimeTypes.docx,
  [fileFormats.xls]: mimeTypes.xls,
  [fileFormats.xlsx]: mimeTypes.xlsx,
  [fileFormats.pdf]: mimeTypes.pdf,
  [fileFormats.msg]: mimeTypes.msg,
};

export const useMimeTypeFromFormat = (formats: string[]): mimeTypes[] => {
  return useMemo(() => {
    if (!formats || !formats.length) {
      return null;
    }
    const mappedFormats = formats.map((item) => item.split('').splice(1).join(''));
    const mimeTypes = new Set(
      mappedFormats.map((item) => formatsAndMimeTypeMap[item as fileFormats])
    );
    return Array.from(mimeTypes);
  }, [formats]);
};
