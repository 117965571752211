import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { baseUrl, isDevelopment } from 'src/environment';

function configureAxiosGlobalSettings(): void {
  axios.defaults.baseURL = baseUrl;
}

export function initializeAxios(): AxiosInstance {
  if (isDevelopment) {
    configureAxiosGlobalSettings();
  }

  const configuration: AxiosRequestConfig = {
    headers: {
      audienceId: 'acaaf79e-5baa-4738-85d8-005782ea8013',
    },
  };

  if (isDevelopment) {
    configuration.baseURL = baseUrl;
  }

  return axios.create(configuration);
}
