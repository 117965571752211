import React from 'react';
import { Navigate } from 'react-router-dom';

export const withAuthValidation =
  (isAuth: boolean) => (component: () => React.ReactElement) => () => {
    if (!isAuth) {
      return <Navigate to='/login' />;
    }
    return component();
  };
