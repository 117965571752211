interface IStorageService {
  setItem(key: string, value: unknown): void;
  getItem(key: string): unknown;
  removeItem(key: string): void;
}

export class StorageService implements IStorageService {
  storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  public setItem(key: string, value: unknown): void {
    const stringifiedValue = JSON.stringify(value);
    this.storage.setItem(key, stringifiedValue);
  }

  public getItem(key: string): unknown {
    return this.storage.getItem(key);
  }

  public clear(): void {
    this.storage.clear();
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  public get length(): number {
    return this.storage.length;
  }

  public setTokens(accessToken: string, refreshToken: string): void {
    this.storage.setItem('accessToken', accessToken);
    this.storage.setItem('refreshToken', refreshToken);
  }

  public removeTokens(): void {
    this.storage.removeItem('accessToken');
    this.storage.removeItem('refreshToken');
  }
}
