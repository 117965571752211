export enum reactQueryKeys {
  contracts = 'contracts',
  damages = 'damages',
  contractDetails = 'contractDetails',
  damageDetails = 'damageDetails',
  dashboard = 'dashboard',
  newsList = 'newsList',
  faqList = 'faqList',
  usersList = 'usersList',
  searchContracts = 'searchContracts',
  customer = 'customer',
  damageDocuments = 'damageDocuments',
  contractDocuments = 'contractDocuments',
  customerDocuments = 'customerDocuments',
  umUserData = 'umUserData',
  availableDocumentFormats = 'availableDocumentFormats',
}
