import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
} from 'mobx-state-tree';

import { FileMstType } from 'src/mst/types';
import { ContractDetailsDto } from 'src/shared/types';
import { FileDto } from 'src/shared/types';

export const ContractDetails = types
  .model('ContractDetailsModel', {
    amsIdNr: types.maybeNull(types.string),
    contractNumber: types.maybeNull(types.string),
    insuranceCompany: types.maybeNull(types.string),
    division: types.maybeNull(types.string),
    annualPremiumInclusiveTaxes: types.maybeNull(types.number),
    product: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    startDate: types.maybeNull(types.string),
    endDate: types.maybeNull(types.string),
    paymentMethod: types.maybeNull(types.string),
    premiumInclusiveTaxes: types.maybeNull(types.number),
    customer: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    postcode: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    risk: types.maybeNull(types.string),
    files: types.maybeNull(types.array(FileMstType)),
  })
  .actions((self) => {
    let initialState: IContractDetailsSnapshotOut;
    const {
      env: { httpClient },
    } = getEnv(self);
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetContracts(): void {
        applySnapshot(self, initialState);
      },
      fetchContractDocuments: flow(function* ({ queryKey }: { queryKey: string[] }) {
        const [, id] = queryKey;
        if (!id) {
          return;
        }
        const data: FileDto[] = yield httpClient.get(`contracts/${id}/documents`);

        applySnapshot(self, {
          ...self,
          files:
            data?.map((item) => ({
              id: item.amsIdNr,
              fileName: `${item.title}.${item.type}`,
            })) || [],
        });
      }),
      fetchContractDetails: flow(function* ({ queryKey }: { queryKey: string[] }) {
        const [, id] = queryKey;
        if (!id) {
          return;
        }
        const data: ContractDetailsDto = yield httpClient.get(`contracts/${id}`);
        applySnapshot(self, data);
      }),
    };
  })
  .views((self) => {
    return {
      get ownContractDetails(): Omit<
        IContractDetailsSnapshotOut,
        'customer' | 'street' | 'postcode' | 'city'
      > {
        return {
          amsIdNr: self.amsIdNr,
          status: self.status,
          contractNumber: self.contractNumber,
          insuranceCompany: self.insuranceCompany,
          startDate: self.startDate,
          endDate: self.endDate,
          paymentMethod: self.paymentMethod,
          premiumInclusiveTaxes: self.premiumInclusiveTaxes,
          division: self.division,
          annualPremiumInclusiveTaxes: self.annualPremiumInclusiveTaxes,
          product: self.product,
          risk: self.risk,
          files: self.files,
        };
      },
      get applicationContractDetails(): Omit<
        IContractDetailsSnapshotOut,
        'startDate' | 'endDate' | 'premiumInclusiveTaxes' | 'paymentMethod'
      > {
        return {
          amsIdNr: self.amsIdNr,
          status: self.status,
          contractNumber: self.contractNumber,
          insuranceCompany: self.insuranceCompany,
          customer: self.customer,
          street: self.street,
          postcode: self.postcode,
          city: self.city,
          division: self.division,
          annualPremiumInclusiveTaxes: self.annualPremiumInclusiveTaxes,
          product: self.product,
          risk: self.risk,
          files: self.files,
        };
      },
      get contractDetails():
        | Omit<
            IContractDetailsSnapshotOut,
            'startDate' | 'endDate' | 'premiumInclusiveTaxes' | 'paymentMethod'
          >
        | Omit<IContractDetailsSnapshotOut, 'customer' | 'street' | 'postcode' | 'city'> {
        return self.status === 'own'
          ? this.applicationContractDetails
          : this.ownContractDetails;
      },
    };
  });

export type ContractDetailsModel = Instance<typeof ContractDetails>;
export type IContractDetailsSnapshotOut = SnapshotOut<typeof ContractDetails>;
export type IContractDetailsSnapshotIn = SnapshotIn<typeof ContractDetails>;
