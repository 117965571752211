export enum damageReportFieldNames {
  isImmediateHelpRequired = 'isImmediateHelpRequired',
  reason = 'reason',
  isAccident = 'isAccident',
  isOwnVehicleDamaged = 'isOwnVehicleDamaged',
  assignments = 'assignments',
  damageType = 'damageType',
  isOtherPartyInvolved = 'isOtherPartyInvolved',
  responsibility = 'responsibility',
  thirdPartyFirstName = 'thirdPartyFirstName',
  thirdPartyLastName = 'thirdPartyLastName',
  thirdPartyStreet = 'thirdPartyStreet',
  thirdPartyPostcode = 'thirdPartyPostcode',
  thirdPartyCity = 'thirdPartyCity',
  thirdPartyPhoneNumber = 'thirdPartyPhoneNumber',
  thirdPartyLicenseNumber = 'thirdPartyLicenseNumber',
  addMoreAccidentOpponents = 'addMoreAccidentOpponents',
  damageDay = 'damageDay',
  damageTime = 'damageTime',
  licenseNumber = 'licenseNumber',
  damageCause = 'damageCause',
  driverFirstName = 'driverFirstName',
  driverLastName = 'driverLastName',
  driverStreet = 'driverStreet',
  driverPostcode = 'driverPostcode',
  driverCity = 'driverCity',
  driverPhoneNumber = 'driverPhoneNumber',
  detailedDamageDescription = 'detailedDamageDescription',
  breakdownStreet = 'breakdownStreet',
  breakdownPostcode = 'breakdownPostcode',
  breakdownCity = 'breakdownCity',
  breakdownCountry = 'breakdownCountry',
  recorderByThePolice = 'recorderByThePolice',
  receivingPoliceStation = 'receivingPoliceStation',
  policeFileNumber = 'policeFileNumber',
  alcoholOrDrugUse = 'alcoholOrDrugUse',
  warnedByThePolice = 'warnedByThePolice',
  whoWasWarnedByThePolice = 'whoWasWarnedByThePolice',
  isDriverLicenseAvailable = 'isDriverLicenseAvailable',
  driverLicenseClass = 'driverLicenseClass',
  dateOfIssue = 'dateOfIssue',
  isWitnesses = 'isWitnesses',
  witnessName = 'witnessName',
  witnessStreet = 'witnessStreet',
  witnessPostcode = 'witnessPostcode',
  witnessCity = 'witnessCity',
  witnessPhoneNumber = 'witnessPhoneNumber',
  withnessEmail = 'withnessEmail',
  attachments = 'attachments',
  fullyComprehensive = 'fullyComprehensive',
  partiallyComprehensive = 'partiallyComprehensive',
  liability = 'liability',
}
