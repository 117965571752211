import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
} from 'mobx-state-tree';

export const Contract = types
  .model('ContractModel', {
    amsIdNr: types.string,
    risk: types.maybeNull(types.string),
  })
  .actions((self) => {
    let initialState: IContractSnapshotOut;
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetContract(): void {
        applySnapshot(self, initialState);
      },
    };
  });

export type ContractModel = Instance<typeof Contract>;
export type IContractSnapshotOut = SnapshotOut<typeof Contract>;
export type IContractSnapshotIn = SnapshotIn<typeof Contract>;
