export enum AppRoutes {
  root = '',
  login = '/login',
  dashboard = '/dashboard',
  contracts = '/contracts',
  contract = '/contracts/:id',
  damages = '/damages',
  damage = '/damages/:id',
  damageReport = '/damage-report/:id',
  userManagement = '/user-management/:tab',
  customers = '/customer',
  customer = '/customer/:id',
  changePassword = '/change-password',
  createPassword = '/create-password',
  forgotPassword = '/forgot-password',
  faq = 'faq',
}
