import { SpacingOptions } from '@mui/system';
import { BreakpointsOptions, Components, PaletteOptions } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { appInstances } from 'src/shared/constants';

import {
  defaultThemeSpacings,
  defaultThemeBreakpoints,
  defaultThemeTypography,
  defaultLightThemeComponents,
  defaultDarkThemeComponents,
  defaultThemeLightPalette,
  defaultThemeDarkPalette,
  uberDarkThemeComponents,
  uberLightThemeComponents,
  uberThemeBreakpoints,
  uberThemeDarkPalette,
  uberThemeLightPalette,
  uberThemeSpacings,
  uberThemeTypography,
} from './themes';

import { themeModes } from './themeModes';

export const themeSpacingsFactory = (appInstance: string): SpacingOptions => {
  switch (appInstance) {
    case appInstances.general: {
      return defaultThemeSpacings;
    }
    case appInstances.uber: {
      return uberThemeSpacings;
    }
    default: {
      return defaultThemeSpacings;
    }
  }
};

export const themeBreakpointsFacotry = (appInstance: string): BreakpointsOptions => {
  switch (appInstance) {
    case appInstances.general: {
      return defaultThemeBreakpoints;
    }
    case appInstances.uber: {
      return uberThemeBreakpoints;
    }
    default: {
      return defaultThemeBreakpoints;
    }
  }
};

export const themeTypographyFactory = (appInstance: string): TypographyOptions => {
  switch (appInstance) {
    case appInstances.general: {
      return defaultThemeTypography;
    }
    case appInstances.uber: {
      return uberThemeTypography;
    }
    default: {
      return defaultThemeTypography;
    }
  }
};

export const themeComponentsFactory = (
  appInstance: string,
  mode: themeModes
): Components => {
  const isLight = mode === themeModes.light;
  switch (appInstance) {
    case appInstances.general: {
      return isLight ? defaultLightThemeComponents : defaultDarkThemeComponents;
    }
    case appInstances.uber: {
      return isLight ? uberLightThemeComponents : uberDarkThemeComponents;
    }
    default: {
      return isLight ? defaultLightThemeComponents : defaultDarkThemeComponents;
    }
  }
};

export const themePaletteFactory = (
  appInstance: string,
  mode: themeModes
): PaletteOptions => {
  const isLight = mode === themeModes.light;
  switch (appInstance) {
    case appInstances.general: {
      return isLight ? defaultThemeLightPalette : defaultThemeDarkPalette;
    }
    case appInstances.uber: {
      return isLight ? uberThemeLightPalette : uberThemeDarkPalette;
    }
    default: {
      return isLight ? defaultThemeLightPalette : defaultThemeDarkPalette;
    }
  }
};
