import { object, boolean, string, InferType } from 'yup';

import { damageReportFieldNames } from 'src/pages/DamageReportPage/constants';

export const otherPartyInvolvedSchema = object({
  [damageReportFieldNames.isOtherPartyInvolved]: boolean().required().nullable(),
  [damageReportFieldNames.responsibility]: string().when(
    damageReportFieldNames.isOtherPartyInvolved,
    {
      is: (val: boolean) => val,
      then: string().required().nullable(),
      otherwise: string().nullable(),
    }
  ),
});

export type OtherPartyInvlovedSchemaType = InferType<typeof otherPartyInvolvedSchema>;
