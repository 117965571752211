import React, { useMemo } from 'react';
import { AxiosContext } from './use-http-client';
import { initializeAxios } from './initialize-axios';
import { AxiosInstance } from 'axios';

interface Props {
  children?: React.ReactElement;
}

export function HttpInfrastructure(props: Props): JSX.Element {
  const axios = useMemo<AxiosInstance>(initializeAxios, []);

  return <AxiosContext.Provider value={axios}>{props.children}</AxiosContext.Provider>;
}
