import { TypographyOptions } from '@mui/material/styles/createTypography';

export const uberThemeTypography: TypographyOptions = {
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: '4rem',
    fontWeight: 700,
    color: '#3D3D3D',
  },
  h2: {
    fontSize: '3.5rem',
    fontWeight: 700,
    color: '#3D3D3D',
  },
  h3: {
    fontSize: '3rem',
    fontWeight: 700,
    color: '#3D3D3D',
  },
  h4: {
    fontSize: '2.5rem',
    fontWeight: 700,
    color: '#3D3D3D',
  },
  h5: {
    fontSize: '2rem',
    fontWeight: 700,
    color: '#3D3D3D',
  },
  h6: {
    fontSize: '1.6rem',
    fontWeight: 700,
    color: '#3D3D3D',
  },
  body1: {
    fontSize: '1.8rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '1.6rem',
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: '1.3rem',
  },
  subtitle2: {
    fontSize: '1rem',
  },
};
