import { PaletteOptions } from '@mui/material';
import { uberThemeLightColors, uberThemeDarkColors } from './uberThemeColors';
import { themeModes } from 'src/theming/themeModes';

export const uberThemeLightPalette: PaletteOptions = {
  mode: themeModes.light,
  primary: {
    main: uberThemeLightColors.primaryMainColor,
    light: uberThemeLightColors.primaryLightColor,
    dark: uberThemeLightColors.primaryDarkColor,
    contrastText: uberThemeLightColors.primaryContrastText,
  },
  secondary: {
    main: uberThemeLightColors.secondaryMainColor,
    light: uberThemeLightColors.secondaryLightColor,
    dark: uberThemeLightColors.secondaryDarkColor,
    contrastText: uberThemeLightColors.secondaryContrastText,
  },
  info: {
    main: uberThemeLightColors.infoMainColor,
    light: uberThemeLightColors.infoLightColor,
    dark: uberThemeLightColors.infoDarkColor,
    contrastText: uberThemeLightColors.infoContrastText,
  },
  error: {
    main: uberThemeLightColors.errorMainColor,
    light: uberThemeLightColors.errorLightColor,
    dark: uberThemeLightColors.errorDarkColor,
    contrastText: uberThemeLightColors.errorContrastTextColor,
  },
};

export const uberThemeDarkPalette: PaletteOptions = {
  mode: themeModes.dark,
  primary: {
    main: uberThemeDarkColors.primaryMainColor,
    light: uberThemeDarkColors.primaryLightColor,
    dark: uberThemeDarkColors.primaryDarkColor,
    contrastText: uberThemeDarkColors.primaryContrastText,
  },
  secondary: {
    main: uberThemeDarkColors.secondaryMainColor,
    light: uberThemeDarkColors.secondaryLightColor,
    dark: uberThemeDarkColors.secondaryDarkColor,
    contrastText: uberThemeDarkColors.secondaryContrastText,
  },
  info: {
    main: uberThemeDarkColors.infoMainColor,
    light: uberThemeDarkColors.infoLightColor,
    dark: uberThemeDarkColors.infoDarkColor,
    contrastText: uberThemeDarkColors.infoContrastText,
  },
  error: {
    main: uberThemeDarkColors.errorMainColor,
    light: uberThemeDarkColors.errorLightColor,
    dark: uberThemeDarkColors.errorDarkColor,
    contrastText: uberThemeDarkColors.errorContrastTextColor,
  },
};
